#pitch-perfekt-download {
  padding: 85px 0;
  background: white;

  .download-oh {
    padding-bottom: 70px;

    h2 {
      color: #151515;
    }
  }

  .download-details {
    padding-top: 32px;
  
    p {
      color: #666;
      line-height: 25px;
      padding-bottom: 30px;
      font-size: 16px;
      font-family: rr;
    }

    a {
      font-size: 16px;
      color: white;
      background: #fa573a;
      font-family: rb;
      border: 2px solid #fa573a;
      padding: .75rem 1.25rem;
      border-radius: 50px;
      -webkit-transition: all linear .3s;
      -o-transition: all linear .3s;
      transition: all linear .3s;

      &:hover {
        color: #fa573a;
        background: transparent;
        border: 2px solid #fa573a;
      }
    }
  }

  .download-item {
    background: transparent;
    text-align: center;
    padding: 30px 0 42px 0;
    -webkit-transition: all linear .3s;
    -o-transition: all linear .3s;
    transition: all linear .3s;
    border: 2px solid #fa573a;
    border-radius: .25rem; // 3px;

    .download-icon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      text-align: center;
      margin: 0 auto;
      border: 2px solid #fa573a;
      -webkit-transition: all linear .3s;
      -o-transition: all linear .3s;
      transition: all linear .3s;
      background: #fa573a;
      align-content: center;
    
      /*
      i {
        color: white;
        font-size: 30px;
        line-height: 80px;
        -webkit-transition: all linear .3s;
        -o-transition: all linear .3s;
        transition: all linear .3s;
      }
      */

      svg {
        color: white;
        font-size: 2rem;
        // line-height: 80px;
        vertical-align: middle;
        -webkit-transition: all linear .3s;
        -o-transition: all linear .3s;
        transition: all linear .3s;
      }
    }

    .di-2 {
      border: 2px solid rgb(125, 122, 255);
      background: rgb(125, 122, 255);
    }
    
    .di-3 {
      border: 2px solid rgb(3, 193, 244);
      background: rgb(3, 193, 244);
    }
    
    .di-4 {
      border: 2px solid #ff467e;
      background: #ff467e;
    }

    h3 {
      color: #fa573a;
      padding-top: 24px;
      padding-bottom: 13px;
      font-family: rb;
      // text-transform: uppercase;
      font-size: 23px;
    }
    
    p {
      color: #666;
      padding: 0 32px 15px;
      line-height: 25px;
      font-size: 15px;
      font-family: rr;
    }

    a {
      border: 2px solid #fa573a;
      padding: .75rem 1.25rem;
      color: #fa573a;
      border-radius: 50px;
      font-size: 16px;
      font-family: rb;
      -webkit-transition: all linear .3s;
      -o-transition: all linear .3s;
      transition: all linear .3s;

      &:hover {
        background: #fa573a;
        color: white;
      }
    }

    &:hover .download-icon i,
    &:hover .download-icon svg {
      -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
      transform: scale(1.2);
    }
  }

  .download-item-2 {
    margin-top: 25px;
    border: 2px solid rgb(125, 122, 255);

    h3 {
      color: rgb(125, 122, 255);
    }

    a {
      border: 2px solid rgb(125, 122, 255);
      color: rgb(125, 122, 255);
    
      &:hover {
        background: rgb(125, 122, 255);
      }
    }
  }

  .download-item-3 {
    margin-top: 25px;
    border: 2px solid rgb(3, 193, 244);

    h3 {
      color: rgb(3, 193, 244);
    }

    a {
      border: 2px solid rgb(3, 193, 244);
      color: rgb(3, 193, 244);

      &:hover {
        background: rgb(3, 193, 244);
      }
    }
  }

  .download-item-4 {
    margin-top: 25px;
    border: 2px solid #ff467e;

    h3 {
      color: #ff467e;
    }

    a {
      border: 2px solid #ff467e;
      color: #ff467e;

      &:hover {
        background: #ff467e;
      }
    }
  }

  .download-p {
    padding-top: 30px;
  }

  .download-head {
    font-family: rb;

    h2 {
      color: #21252e;
      font-size: 28px;
      text-transform: uppercase;
    }

    h3 {
      color: #fa573a;
      font-size: 23px;
    }
  }

  @media(max-width:575px) {
    .download-details p {
      padding-bottom: 10px;
    }

    .download-p {
      padding-top: 16px;
    }

    .download-details {
      padding-bottom: 75px;
      padding-top: 15px;
    }

    .download-item p {
      padding: 0 10px 15px;
    }

    #download {
      padding: 85px 0 30px;
    }
  }

  @media(min-width:576px) and (max-width:767px) {
    .download-details {
      padding-bottom: 50px;
      padding-top: 15px;
    }

    .download-item p {
      padding: 0 0 15px;
    }

    #download {
      padding: 85px 0 50px;
    }
  }

  @media(min-width:768px) and (max-width:991px) {
    .download-p {
      padding-top: 15px;
    }

    .download-details {
        padding-bottom: 50px;
        padding-top: 15px;
    }

    .download-item p {
        padding: 0 24px 15px;
    }

    #download {
        padding: 85px 0 50px;
    }
  }
}
