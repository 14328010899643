#user-guide {
  padding: 80px 0px;

  hr {
    color: #666;
    background-color: #666;
    display: block;
    margin-top: 0.5em;
    margin-bottom: 1.5em;
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    border-width: 0px;
    height: 1px;
  }

  .toc {
    padding-inline-start: 1rem;
  }

  .user-guide-text {
    h2 {
      font-family: rb;
      font-size: 28px;
      color: #fa573a;
      text-transform: uppercase;
    }
    
    h3 {
      font-family: rb;
      font-size: 23px;
      color: white;
    }
    
    h4 {
      font-family: rb;
      font-size: 20px;
      color: white;
    }

    .important-box {
      border: 3px solid #666;
      border-radius: 1rem;
      padding: 1rem 1rem 0 1rem;
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
    }

    p {
      font-family: rr;
      text-align: justify;
      font-size: 16px;
      line-height: 25px;
      color: white;

      strong {
        font-family: rb;
      }
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    li {
      font-family: rr;
      font-size: 16px;
      line-height: 25px;
      color: white;
    }

    dl {
      color: white;
    }

    /*
    dt {
      font-family: rb
    }
    */

    dd {
      font-family: rr;
      font-size: 16px;
      line-height: 25px;
    }

    a {
      font-family: rr;
      font-size: 16px;
      color: #fa573a;
      -webkit-transition: all linear .3s;
      -o-transition: all linear .3s;
      transition: all linear .3s;

      &:hover {
        opacity: 0.5;
      }
    }

    .other-daws {
      padding: 0px;

      a.daw-link {
        display: block;
        padding: 3px 0;

        &:hover {
          padding-left: 10px;
        }
      }
    }
  }
}
