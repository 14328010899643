#privacy {
  padding: 80px 0px;
  color: white;

  .privacy-text {
    h1 {
      color: #fa573a;
      // font-family: osb;
      // font-size: 28px;
      // text-transform: uppercase;
    }

    /*
    h2 {
      // color: white;
      // font-family: rb;
      // font-size: 28px;
      // text-transform: uppercase;
    }
    
    h3 {
      // color: white;
      // font-size: 23px;
      // font-family: rb;
      // text-transform: capitalize;
    }
    
    h4 {
      // color: white;
      // font-size: 23px;
      // font-family: rb;
      // text-transform: capitalize;
    }

    p {
      // color: white;
      // font-size: 15px;
      // font-family: rr;
      // line-height: 25px;
      // padding-bottom: 16px;
      // padding-top: 10px;
    }

    li {
      // color: white;
    }

    dt {
      // font-family: rr;
      // font-size: 15px;
      // color: white;
    }

    dd {
      // font-family: rr;
      // font-size: 15px;
      // color: white;
    }
    */

    ul {
      list-style-type: disc;
    }

    a {
      font-weight: bolder;
      color: #fa573a;
      -webkit-transition: all linear .3s;
      -o-transition: all linear .3s;
      transition: all linear .3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
