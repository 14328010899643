.user-consent-container {
  background: #21252e;
  border: none;
  border-radius: 0rem;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 9999;
  margin-bottom: 0rem;
  -webkit-box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.3), 0 -15px 15px rgba(0, 0, 0, .2);
  box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.3), 0 -15px 15px rgba(0, 0, 0, .2);
}

.user-consent-content {
  flex: 1 0 300px;
  margin: 15px;
  font-family: rr;

  a {
    font-family: rb;
    color: #fa573a;
    -webkit-transition: all linear .3s;
    -o-transition: all linear .3s;
    transition: all linear .3s;

    &:hover {
      opacity: .5;
    }
  }
}

.user-consent-button {
  background: #fa573a;
  border: 2px solid #fa573a;
  border-radius: 50px;
  box-shadow: none;
  color: white;
  cursor: pointer;
  // flex: 0 0 auto;
  padding: .75rem 1.25rem;
  font-size: 16px;
  font-family: rb;
  -webkit-transition: all linear .3s;
  -o-transition: all linear .3s;
  transition: all linear .3s;
  margin: 15px;
  text-transform: capitalize;
  
  &:hover {
    background: none;
    color: #fa573a;
  }

  &:focus {
    outline: none;
  }
}

.user-decline-button {
  background: transparent;
  border: 2px solid #fa573a;
  border-radius: 50px;
  box-shadow: none;
  color: #fa573a;
  cursor: pointer;
  // flex: 0 0 auto;
  padding: .75rem 1.25rem;
  font-size: 16px;
  font-family: rb;
  -webkit-transition: all linear .3s;
  -o-transition: all linear .3s;
  transition: all linear .3s;
  margin: 15px;
  text-transform: capitalize;

  &:hover {
    background: #fa573a;
    color: white;
  }

  &:focus {
    outline: none;
  }
}
