#about-us {
  padding: 80px 0px;

  .about-us-text {
    h2 {
      font-family: rb;
      font-size: 28px;
      color: #fa573a;
      text-transform: uppercase;
    }
    
    p {
      font-family: rr;
      font-size: 16px;
      line-height: 25px;
      color: white;
    }

    a {
      font-family: rb;
      font-size: 16px;
      color: #fa573a;
      -webkit-transition: all linear .3s;
      -o-transition: all linear .3s;
      transition: all linear .3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
