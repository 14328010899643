#soroosh-mohassesi {
  padding: 60px 0 30px;
  background: #fafafa;

  svg {
    font-size: 2.25rem; // 30px;
    color: #fa573a;
  }
  
  .testimonial p {
    font-size: 16px;
    padding: 20px 0px 0px;
    line-height: 26px;
    font-family: rr;
    color: #666;
  }

  .testimonial h3 {
    font-family: rb;
    font-size: 26px;
  }
  
  .testimonial span {
    font-family: rr;
    font-size: 16px;
    color: #fa573a;
  }

  .testimonial-img img {
    border: 3px solid #fa573a;
  }
}
