.backtotop {
  display: none;
  border-radius: 50%;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 999;
  -moz-transition: all linear .3s;
  -webkit-transition: all linear .3s;
  -o-transition: all linear .3s;
  transition: all linear .3s;
  -moz-box-shadow: 0 20px 20px rgba(0, 0, 0, .3), 0 15px 15px rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 20px 20px rgba(0, 0, 0, .3), 0 15px 15px rgba(0, 0, 0, .2);
  box-shadow: 0 20px 20px rgba(0, 0, 0, .3), 0 15px 15px rgba(0, 0, 0, .2);

  svg {
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    padding: .75rem;
    vertical-align: middle;
    background: #fa573a;
    border-radius: 50%;
    cursor: pointer;
  }
}

/*
.backtotop i {
  color: white;
  text-align: center;
  font-size: 25px;
  line-height: 45px;
  width: 45px;
  height: 45px;
  background: #fa573a;
  // border: 2px solid #fa573a;
  border-radius: 50%;
  // -webkit-transform: rotate(-45deg);
  // -ms-transform: rotate(-45deg);
  // transform: rotate(-45deg);
  // -webkit-box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  // box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  cursor: pointer;
}
*/
