@import '../../constants/style';

.video-player-popup-content {
  margin: auto;
  background: $white_1;
  width: fit-content;
  height: fit-content;
  padding: 0;
  border: unset;
}

@media (max-width: 650px) {
  .video-player-popup-content {
    div {
      width: 450px !important;
      height: 255px !important;
    }
  }
}

@media (max-width: 450px) {
  .video-player-popup-content {
    div {
      width: 300px !important;
      height: 175px !important;
    }
  }
}
