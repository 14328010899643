#thank-you {
  padding: 80px 0;

  .thank-you-p {
    padding-top: 90px;
  }

  .thank-you-text {
    padding-left: 48px;
    padding-bottom: 40px;

    h2 {
      font-family: rb;
      color: #fa573a;
      font-size: 28px;
      padding-top: 85px;
      text-transform: uppercase;
    }
    
    h3 {
      font-family: rb;
      color: white;
      font-size: 23px;
      padding-bottom: 4px;
      text-transform: capitalize;
    }

    p {
      padding-bottom: 20px;
      font-size: 16px;
      line-height: 25px;
      font-family: rr;
      color: white;
    }

    a.support-email {
      font-weight: bolder;
      color: #fa573a;
      -webkit-transition: all linear .3s;
      -o-transition: all linear .3s;
      transition: all linear .3s;

      &:hover {
        opacity: 0.5;
      }
    }

    a.go-back-button {
      color: white;
      font-size: 16px;
      background: #fa573a;
      border: 2px solid #fa573a;
      padding: .75rem 1.25rem;
      margin-bottom: 20px;
      border-radius: 50px;
      font-family: rb;
      -webkit-transition: all linear .3s;
      -o-transition: all linear .3s;
      transition: all linear .3s;
    
      &:hover {
        background: none;
        color: #fa573a;
      }
    }
  }

  .thank-you-svg {
    position: relative;
    z-index: 1;

    svg {
      width: 275px;
      height: auto;
      // border-radius: 50%;
      // background: #fa573a;
      // margin-right: 18px;
      color: #fa573a;
      // padding: .75rem;
      vertical-align: middle;
    }

    &::after {
      position: absolute;
      content: '';
      width: 375px;
      height: 375px;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      left: 50%;
      border: 30px solid #fa573a;
      border-radius: 50%;
      z-index: -1;
    }
  }
}

@media(max-width: 575px) {
  .thank-you-svg::after {
    width: 230px !important;
    height: 230px !important;
  }

  .thank-you-svg svg {
    width: 150px !important;
  }

  .thank-you-text {
    padding-left: 20px !important;
  }

  .thank-you-text h2 {
    padding-top: 75px !important;
  }

  .thank-you-text p {
    font-size: 15px !important;
  }

  #thank-you {
    padding: 38px 0 !important;
  }
}

@media(min-width: 576px) and (max-width: 767px) {
  .thank-you-text h2 {
    padding-top: 29px !important;
  }

  .thank-you-text {
    padding-left: 15px !important;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .thank-you-text h2 {
    padding-top: 40px !important;
  }
}

@media(min-width: 992px) and (max-width: 1124px) {
  .thank-you-img::after {
    width: 450px !important;
    height: 450px !important;
  }
}
