#transkr-v2-features {
  padding: 80px 0;
  background: white;

  /*
  .title-head {
    margin-bottom: 95px;
  }
  */

  .plugin-p {
    padding-top: 90px;
  }

  .plugin-text {
    padding-left: 48px;
  }

  .plugin-fretboad {
    justify-content: center;
  }

  .plugin-text h2 {
    font-family: rb;
    color: #21252e;
    font-size: 28px;
    padding-top: 15px;
    text-transform: uppercase;
  }

  .plugin-text h3 {
    font-family: rb;
    color: #fa573a;
    font-size: 23px;
    padding-bottom: 4px;
    // text-transform: capitalize;
  }

  .plugin-text p i {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #fa573a;
    margin-right: 18px;
    font-size: 20px;
    text-align: center;
    line-height: 45px;
    color: white;
  }

  .plugin-text p svg {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background: #fa573a;
    margin-right: 18px;
    color: white;
    padding: .75rem;
    vertical-align: middle;
  }

  .plugin-text p {
    font-size: 16px;
    font-family: rb;
  }

  .plugin-img {
    position: relative;
    z-index: 1;
  }

  .plugin-img::after {
    position: absolute;
    content: '';
    width: 500px;
    height: 500px;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    border: 30px solid #fa573a;
    border-radius: 50%;
    z-index: -1;
  }

  .plugin-p-padding {
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 25px;
    font-family: rr !important;
    color: #666;
  }

  /*
  .plugin-col-i {
    padding-right: 0;
    max-width: fit-content;
  }

  .plugin-col-p {
    padding-left: 0;

    p {
      margin-bottom: 0;
    }
  }

  .plugin-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    -o-transition: all linear .3s;
    -webkit-transition: all linear .3s;
    transition: all linear .3s;
  }

  .plugin-img:hover .plugin-overlay {
    opacity: .5;
  }

  .plugin-button {
    position: absolute;
    width: 90px;
    height: 90px;
    line-height: 90px;
    font-size: 30px;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: white;
    color: #21252e;
    cursor: pointer;
    -o-transition: all linear .3s;
    -webkit-transition: all linear .3s;
    transition: all linear .3s;

    svg {
      vertical-align: middle;
    }
  }

  .plugin-button:hover {
    color: #fa573a;
  }
  */

  @media(max-width:575px) {
    .plugin-img::after {
      width: 300px;
      height: 300px;
    }

    .plugin-text {
      padding-left: 20px;
    }

    .plugin-text h2 {
      padding-top: 75px;
    }

    .plugin-text p {
      font-size: 15px;
    }

    #plugin {
      padding: 38px 0;
    }
  }

  @media(min-width:576px) and (max-width:767px) {
    .plugin-text h2 {
      padding-top: 29px;
    }

    .plugin-text {
      padding-left: 15px;
    }
  }

  @media(min-width:768px) and (max-width:991px) {
    .plugin-text h2 {
      padding-top: 40px;
    }
  }

  @media(min-width:992px) and (max-width:1124px) {
    .plugin-img::after {
      width: 450px;
      height: 450px;
    }
  }
}
