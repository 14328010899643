#terms {
  padding: 80px 0px;

  .toc {
    padding-inline-start: 1rem;
  }

  .terms-text {
    h2 {
      font-family: rb;
      font-size: 28px;
      color: #fa573a;
      text-transform: uppercase;
    }
    
    h3 {
      font-family: rb;
      font-size: 23px;
      color: white;
    }
    
    h4 {
      font-family: rb;
      font-size: 20px;
      color: white;
    }

    p {
      font-family: rr;
      font-size: 16px;
      line-height: 25px;
      color: white;
    }

    ul {
      list-style-type: disc;
    }

    li {
      color: white;
    }

    /*
    dt {
      font-family: rr;
      font-size: 15px;
      color: white;
    }

    dd {
      font-family: rr;
      font-size: 15px;
      color: white;
    }
    */

    a {
      font-family: rb;
      font-size: 16px;
      color: #fa573a;
      -webkit-transition: all linear .3s;
      -o-transition: all linear .3s;
      transition: all linear .3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
