#pricing {
  padding: 80px 0;
}

.pricing-p {
  padding-top: 90px;
}

.pricing-table {
  justify-content: center;
  align-items: center;
  // text-align: center;
}

.pricing-text {
  margin-top: 25px;
  font-size: larger;
  color: white;

  p.pricing-call-to-action {
    font-family: 'rr';
  }
}

.pricing-check-marks {
  padding-left: 15px;

  p {
    font-family: 'rb';

    /*
    i {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      // background: white;
      margin-right: 18px;
      font-size: 25px;
      text-align: center;
      line-height: 45px;
      // color: #21252e;
      color: white;
    }
    */

    svg {
      color: #fa573a;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      margin-right: 1rem;
      vertical-align: middle;
    }
  }
}

.pricing-buy-buttons {
  margin-top: 25px;

  p {
    color: white !important;
  }

  p.pricing-title {
    font-family: rr;
    font-size: x-large;
  }
  
  p.pricing-price {
    font-family: rb;
    font-size: xx-large;
  }

  a {
    display: inline-block;
    width: calc(100% - 100px);
    // font-size: 16px;
    font-size: larger;
    color: white;
    background: #fa573a;
    font-family: rb;
    border: 2px solid #fa573a;
    // padding: .75rem 1.25rem;
    padding: .75rem 0;
    border-radius: 50px;
    -moz-transition: all linear .3s;
    -webkit-transition: all linear .3s;
    -o-transition: all linear .3s;
    transition: all linear .3s;

    &:hover {
      color: #fa573a;
      background: transparent;
    }

    svg {
      height: 2.25rem;
      vertical-align: middle;
      padding: .5rem 0;
    }
  }
}

.pricing-scan-to-pay {
  margin-top: 25px;
  
  .pricing-qr-code {
    padding: 15px 30px;
  }
}

/*
@media (max-width: 575.98px) {
  .pricing-text {
    padding-left: 40px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .pricing-text {
    padding-left: 15px;
  }
}
*/

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .pricing-text p {
      font-size: large;
  }

  .pricing-check-marks p svg {
    width: 1.75rem;
    height: 1.75rem;
  }

  .pricing-buy-buttons {
    p.pricing-title {
      font-size: larger;
    }

    p.pricing-price {
      font-size: x-large;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .pricing-text p {
    font-size: medium;
  }

  .pricing-check-marks p svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .pricing-buy-buttons {
    p.pricing-title {
      font-size: large;
    }

    p.pricing-price {
      font-size: larger;
    }
  }
}

// Medium devices (tablets, 768px and up)
/*
@media (min-width: 768px) and (max-width: 991.98px) {

}
*/

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .pricing-text p {
    font-size: large;
  }

  .pricing-check-marks p svg {
    width: 1.75rem;
    height: 1.75rem;
  }

  .pricing-buy-buttons {
    p.pricing-title {
      font-size: larger;
    }

    p.pricing-price {
      font-size: x-large;
    }
  }
}

// X-Large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) /* and (max-width: 1399.98px) */ {
// 
// }

// XX-Large devices (larger desktops, 1400px and up)
/*
@media (min-width: 1400px) {

}
*/
