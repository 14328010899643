#main-preloader {
  position: fixed;
  z-index: 9999999;
  background-color: #21252e;
  width: 100%;
  height: 100%;
  animation: 2s fadeOut 1s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

#main-preloader:before,
#main-preloader:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translate3d(0, -100%, 0);
  -ms-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
  -webkit-transition: all 800ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -o-transition: all 800ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: all 800ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#main-preloader:after {
  background: #21252e;
  z-index: 13;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

#main-preloader .preloader-percentage.center {
  font-weight: 600;
  font-size: 125px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  -ms-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  width: 120px;
}

#main-preloader .preloader-bar-outer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 4px;
}

#main-preloader .preloader-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #01A2DD;
}

#main-preloader.ani_1:before,
#main-preloader.ani_1:after {
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

#main-preloader .object {
  width: 20px;
  height: 20px;
  background-color: #fa573a;
  border-radius: 50% 50% 50% 50%;
  margin-right: 20px;
  margin-bottom: 20px;
  position: absolute;
}

#main-preloader .object_one {
  -webkit-animation: object 2s linear infinite;
  animation: object 2s linear infinite;
}

#main-preloader .object_two {
  -webkit-animation: object 2s linear infinite -0.4s;
  animation: object 2s linear infinite -0.4s;
  left: 20px;
}

#main-preloader .object_three {
  -webkit-animation: object 2s linear infinite -0.8s;
  animation: object 2s linear infinite -0.8s;
  left: 40px;
}

#main-preloader .object_four {
  -webkit-animation: object 2s linear infinite -1.2s;
  animation: object 2s linear infinite -1.2s;
  left: 60px;
}

#main-preloader .object_five {
  -webkit-animation: object 2s linear infinite -1.6s;
  animation: object 2s linear infinite -1.6s;
  left: 80px;
}

@-webkit-keyframes object {
  0% {
    left: 100px;
    top: 0;
  }

  80% {
    left: 0;
    top: 0;
  }

  85% {
    left: 0;
    top: -20px;
    width: 20px;
    height: 20px;
  }

  90% {
    width: 40px;
    height: 15px;
  }

  95% {
    left: 100px;
    top: -20px;
    width: 20px;
    height: 20px;
  }

  100% {
    left: 100px;
    top: 0;
  }
}

@keyframes object {
  0% {
    left: 100px;
    top: 0;
  }

  80% {
    left: 0;
    top: 0;
  }

  85% {
    left: 0;
    top: -20px;
    width: 20px;
    height: 20px;
  }

  90% {
    width: 40px;
    height: 15px;
  }

  95% {
    left: 100px;
    top: -20px;
    width: 20px;
    height: 20px;
  }

  100% {
    left: 100px;
    top: 0;
  }
}
