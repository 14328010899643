#banner {
  position: relative;
  background: #21252e;
  // padding: 215px 0 80px;
  min-height: calc(100vh - 60px);
  border-bottom-right-radius: 200px;
  margin-bottom: 60px;

  a.bookmark {
    font-family: rr;
    color: #fa573a;
    -webkit-transition: all linear 300ms;
    -o-transition: all linear 300ms;
    transition: all linear 300ms;

    &:hover {
      opacity: 0.5;
    }
  }
}

#banner::before {
  position: absolute;
  content: '';
  bottom: -60px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fa573a;
  z-index: -1;
}

.banner-text h2 {
  color: #fa573a;
  font-family: osb;
  font-size: 50px;
  text-transform: uppercase;
}

.banner-text h3 {
  color: white;
  font-size: 30px;
  font-family: rb;
  text-transform: capitalize;
}

.banner-text p {
  color: white;
  font-size: 15px;
  font-family: rr;
  line-height: 25px;
  // padding-bottom: 15px;
  // padding-top: 10px;
}

.banner-text .pt {
  padding-top: 10px !important;
}

.banner-text .pb {
  padding-bottom: 15px !important;
}

.banner-text .pb-contact {
  padding-bottom: .75rem;
}

.banner-text .app-store-badge img {
  cursor: pointer;
}

.banner-text a.contact-us-button {
  color: white;
  font-size: 16px;
  background: #fa573a;
  border: 2px solid #fa573a;
  padding: .75rem 1.25rem;
  border-radius: 50px;
  font-family: rb;
  -webkit-transition: all linear .3s;
  -o-transition: all linear .3s;
  transition: all linear .3s;

  &:hover {
    background: none;
    color: #fa573a;
  }
}

.banner-img {
  position: relative;
  z-index: 1;
}

.banner-text {
  position: relative;
  padding-top: 65px;
  padding-bottom: 27px;
}

.banner-img::after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 30px solid #fa573a;
  width: 375px;
  height: 375px;
  border-radius: 50%;
  z-index: -1;
}

.image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  text-align: center;
  -o-transition: all linear .3s;
  -webkit-transition: all linear .3s;
  transition: all linear .3s;

  &:hover {
    opacity: .5;
  }
}

.image-button {
  position: absolute;
  width: 90px;
  height: 90px;
  line-height: 85px;
  font-size: 30px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: white;
  color: #21252e;
  cursor: pointer;
  -o-transition: all linear .3s;
  -webkit-transition: all linear .3s;
  transition: all linear .3s;

  svg {
    vertical-align: middle;
  }

  &:hover {
    color: #fa573a;
  }
}

.social-media {
	display: flex;
	justify-content: center;
  margin-top: 20px;
}

.social-media-i {
	padding: 0 5px;
  max-width: fit-content;
}

.social-media-i i {
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  background: transparent;
  font-size: 20px;
  text-align: center;
  color: #fa573a;
  border: 2px solid #fa573a;
  -webkit-transition: all linear .3s;
  -o-transition: all linear .3s;
  transition: all linear .3s;
  cursor: pointer;

  &:hover {
    background: #fa573a;
    color: white;
  }
}

.social-media-i svg {
  width: 1.25rem;
  height: 1.25rem;
  padding: .75rem;
  border-radius: 50%;
  background: transparent;
  color: #fa573a;
  border: 2px solid #fa573a;
  vertical-align: middle;
  -webkit-transition: all linear .3s;
  -o-transition: all linear .3s;
  transition: all linear .3s;
  cursor: pointer;

  &:hover {
    background: #fa573a;
    color: white;
  }
}

/*
@media(max-width: 479px) {
  .banner-img::after {
    width: 160px;
    height: 160px;
  }
}

@media(min-width: 480px) and (max-width: 575px) {
  .banner-img::after {
    width: 233px;
    height: 233px;
  }
}

@media(max-width: 575px) {
  #banner::before {
    bottom: -45px;
  }

  #banner {
    padding: 115px 0 80px;
    margin-bottom: 45px;
  }

  .banner-img {
    padding-top: 65px;

    &::after {
      -webkit-transform: translate(-50%, calc(32.5px - 50%));
      -ms-transform: translate(-50%, calc(32.5px - 50%));
      transform: translate(-50%, calc(32.5px - 50%));
    }
  }

  .image-overlay {
    top: 65px;
    height: calc(100% - 65px);
  }

  .banner-text::after,
  .banner-img::before {
    display: none;
  }

  .circle::after,
  .banner-text::before {
    display: none;
  }
}

@media(min-width: 576px) and (max-width: 767px) {
  .banner-img::after {
    width: 300px;
    height: 300px;
  }

  .banner-text::after,
  .banner-img::before {
    display: none;
  }

  .circle::after,
  .banner-text::before {
    display: none;
  }

  #banner {
    padding: 138px 0 80px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  #banner {
    padding: 170px 0 120px;
  }

  .banner-img::after {
    width: 255px;
    height: 255px;
  }

  .banner-text {
    padding-top: 28px;
  }

  .banner-text::before {
    display: none;
  }

  .banner-text h2 {
    font-size: 35px;
  }
}
*/

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  #banner {
    border-bottom-right-radius: 0px;
  }

  .banner-img-after {
    padding-top: 50px;
  }

  .banner-img::after {
    width: 228px;
    height: 228px;
  }

  .social-media {
    padding-bottom: 15px;
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width: 767.98px) {
  .banner-img-after {
    padding-top: 50px;
  }

  .banner-img::after {
    width: 353px;
    height: 353px;
  }

  .social-media {
    padding-bottom: 15px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991.98px) {
  #banner {
    padding-top: calc((100vh - 562px - 60px) / 2);
  }

  .banner-text h2 {
    font-size: 35px;
  }

  .banner-img::after {
    width: 228px;
    height: 228px;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  #banner {
    padding-top: calc((100vh - 548px - 60px) / 2);
  }

  .banner-img::after {
    width: 367px;
    height: 367px;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) /* and (max-width: 1399.98px) */ {
  #banner {
    padding-top: calc((100vh - 548px - 60px) / 2);
  }
  
  .banner-img::after {
    width: 439px;
    height: 439px;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
/*
@media (min-width: 1400px) {

}
*/
