#testimonial {
  padding: 60px 0 30px;
  background: #fafafa;

  svg {
    font-size: 2.25rem; // 30px;
    color: #fa573a;
  }

  /*
  .testimonial-item {
    padding-top: 35px;
  }
  */
  
  .testimonial-item p {
    font-size: 16px;
    padding: 20px 168px 0;
    line-height: 26px;
    font-family: rr;
    color: #666;
  }
  
  .testimonial-item h3 {
    font-family: rb;
    font-size: 26px;
  }
  
  .testimonial-item span {
    font-family: rr;
    font-size: 16px;
    color: #fa573a;
  }
  
  .testimonial-img-item {
    padding-top: 25px;
  }
  
  .testimonial-img-item img {
    border: 3px solid #fa573a;
    border-radius: 50%;
    display: inline-block;
    text-align: center !important;
    -webkit-transition: all linear 0.3s;
    -o-transition: all linear 0.3s;
    transition: all linear 0.3s;
    margin-bottom: 25px;
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
    cursor: grab;
  
    &:active {
      cursor: grabbing;
    }
  }
  
  .slick-center .testimonial-img-item img {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  @media(max-width: 575px) {
    .testimonial-item p {
      padding: 20px 0 0;
    }
  }
  
  @media(min-width: 576px) and (max-width: 767px) {
    .testimonial-item p {
      padding: 20px 0 0;
    }
  }
  
  @media(min-width: 768px) and (max-width: 991px) {
    .testimonial-item p {
      padding: 20px 56px 0;
    }
  }
}
