#footer {
  padding: 30px 0 60px;
  background: #21252e;

  .brand {
    height: 68px;
  }

  .friendly-support {
    padding-top: 15px;
    
    p {
      font-family: 'rr';
      color: #666;
    }

    .westerberg-guitars {
      text-align: center;

      .logo {
        width: 200px;
      }

      .text {
        font-family: 'rr';
        font-size: 18px;
        color: white;
      }
    }
  }

  .footer-text {
    h3 {
      font-family: rb;
      color: white;
      font-size: 23px;
      position: relative;
      padding-bottom: 8px;

      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 25px;
        height: 2px;
        background: #fa573a;
      }
    }
  
    p {
      font-size: 15px;
      color: white;
      font-family: rr;
      line-height: 24px;
    }
  
    a {
      font-family: rr;
      color: white;
      font-size: 15px;
      display: block;
      padding: 3px 0;
      -webkit-transition: all linear .3s;
      -o-transition: all linear .3s;
      transition: all linear .3s;

      &:hover {
        color: #fa573a;
        padding-left: 10px;
      }
    }
  }
}

/*
.nb-2 {
  font-size: 27px;
  color: white;
  font-family: rb;
  -webkit-transition: all linear .3s;
  -o-transition: all linear .3s;
  transition: all linear .3s;
}

.nb-2:hover {
  color: #fa573a;
}
*/

/*
.fg2 input {
  border-radius: 50px;
  background: none;
  height: 40px;
  color: white;
  border: 2px solid #fa573a;
}

.fg2 input:focus {
  background: none;
  border: 2px solid #fa573a;
  position: relative;
  right: 0;
}

.fg2 button i {
  -webkit-transition: all linear .3s;
  -o-transition: all linear .3s;
  transition: all linear .3s;
}

.fg2 button {
  border-radius: 50px;
  position: absolute;
  right: 16px;
  top: 2px;
  border: none;
  background: #fa573a;
  color: white;
  font-family: rb;
}

.fg2 button:hover {
  border: none;
  background: #fa573a;
}

.fg2 button:hover i {
  padding-right: 8px;
}
*/

@media(max-width: 575px) {
  .footer-text {
    padding-bottom: 20px;
  }
}

@media(min-width: 576px) and (max-width: 767px) {
  .footer-text {
    padding-bottom: 20px;
  }
}

@media(min-width: 768px) and (max-width: 991px) {
  .footer-text {
    padding-bottom: 20px;
  }
}
