#copyright {
	background: #fa573a;
	width: 100%;
}

.copyright-text p {
	font-size: 16px;
	padding: 13px 0 1px;
	color: white;
	font-family: rr;
}

.copyright-text a {
	font-family: rr;
	color: white;
	-webkit-transition: all linear 300ms;
	-o-transition: all linear 300ms;
	transition: all linear 300ms;

	&:hover {
		opacity: 0.5;
	}
}
