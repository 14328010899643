// Colors :

$white_1: #FFFFFF;
$white_2: #dbdbdb;
$white_3: #F2F3F4;
$white_4: #f0f0f0;
$white_5: #dee2e6;
$white_6: #eeeeee;
$white_7: #dddddd;
$white_8: #fafafa;
$white_9: #FFFDFD;
$grey_1: #c7c6c6;
$grey_2: #6C757E;
$grey_3: #495057;
$grey_4: #464646;
$grey_5: #666666;
$grey_6: #6C757D;
$grey_7: #fffdfd63;
$main_orange: #F95640;
$orange_1: #fa573a;
$blue_1: #4267b2;
$blue_2: #21252e;
$blue_2: #212529;
$black_1: #151515;
$black_2: #181C22;
$black_3: #21242e;
$black_4: #181C22;
$black_5: #1d2029;
$green_1: #2bb75a;

