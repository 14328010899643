.promo-container {
  background: #21252e;
  border: none;
  border-radius: 0rem;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: fixed;
  top: 0rem;
  width: 100%;
  z-index: 999;
  box-shadow: 0 20px 20px rgba(0, 0, 0, .3), 0 15px 15px rgba(0, 0, 0, .2);

  .promo-content {
    flex: 1 0 300px;
    margin: 15px;

    .alert-heading {
      font-family: rb;
    }
    
    p {
      font-family: rr;
      margin-bottom: 0rem;
    }

    .alert-link {
      font-family: rr;
      color: #fa573a;
      -webkit-transition: all linear .3s;
      -o-transition: all linear .3s;
      transition: all linear .3s;

      &:hover {
        opacity: .5;
      }
    }
  }

  .dismiss-button {
    background: #fa573a;
    border: 2px solid #fa573a;
    border-radius: 50px;
    color: white;
    // flex: 0 0 auto;
    padding: .75rem 1.25rem;
    font-family: rb;
    -webkit-transition: all linear .3s;
    -o-transition: all linear .3s;
    transition: all linear .3s;
    margin: 15px;
    
    &:hover {
      background: none;
      color: #fa573a;
    }
  
    &:focus {
      outline: none;
    }
  }
}
